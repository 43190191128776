.singleOrder_popupModal{
    width: 600px;
    height: 700px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background-color: #FFFFFF;
    box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    border-radius: 12px;
    
    
}
.singleOrder_popupModal2{
    width: 600px;
    height: 700px;
    position: fixed;
    top: 18%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    background-color: #FFFFFF;
    box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    
}
.modalPageForm{
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 12px;
    overflow-y: scroll;
    
  
}
.productForm2{
    flex-direction: column;
    gap: 10px;
    
}

.single_order_heading h2{
    text-align: center;
    padding: 15px;
}
.single_order_body{
    width: 100%;
    height: 600px;
    margin-top: 10px;
    overflow-y: scroll;
    
  
}
.single_order_lists_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.single_order_list{
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.single_order_list span{
    font-size: 20px;
    font-weight: 300;
}
.single_order_Product_details{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.prdcts-con{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 5px;
}
.user_address{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

@media screen and (max-width:650px){
    .singleOrder_popupModal{
        width: 300px;
        height: 400px;
        overflow-y: scroll;
    }
    .singleOrder_popupModal2{
        width: 300px;
        height: 500px;
    }
    .modalPageForm{
        padding: 10px
    }
    .productFields label{
        font-size: 17px;
    }
    .productFields input{
       height: 30px;
    }
    .addProductTitle:nth-child(2){
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button-3{
        padding: 3px 8px;
        font-size: 12px;
    }
    
}