.loading{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 5s ease;
    background-color: white;
     z-index: 10;
 
}