.analysis_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 16px;
    padding: 16px;
}

.earning_chart,
.transaction {
    height: 570px;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 83vw;

}
.earning_chart {
    width: 100%;
    padding: 40px;
    
    

}
.transaction {
    width: 100%;
    padding: 16px;
}
.reports {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    height: 448px;
    
}
.reports_users {
    flex: 2;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    height: 100%;
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.newUsers,
.top_Products {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    overflow-y: auto;
    font-size: 12px;
}
.reports_topProducts {
    flex: 4;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    height: 100%;
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
.transaction_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 16px;
    height: 66px;
    gap: 5px;
}
.transaction_header p {
    color: #71717A;
}
.transaction_table {
    display: flex;
    height: 375px;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
}
.columnI {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

}
.columnI_heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    height: 50px;
    width: 100%;
    background: #F9FAFB;
    border-bottom: 1px solid #E5E7EB;
}
.columnI_heading span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #6B7280;
}
.column_body {
    padding: 16px;
    height: 50px;
    width: 100%;
}

.topProduct_container{
    width:100%;
    height:60px;
    padding:9px 0px;
    border-bottom:1px solid  #E5E7EB;
}
.topProduct_body_container{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    gap:5px;
    padding-right:20px;
}
.topProduct_body_name span{
    font-size:1rem;
   
}
.topProduct_body_sold{
    width: 100px;
}
.topProduct_body_sold span{
    font-size: 20px;
    
}
@media screen and (min-width:650px) and (max-width:950px){
    .analysis_container{
        width: 100vw;
    }
    .topProduct_body_name span{
        font-size:0.8rem;
        font-weight: 100;
       
    }
    .topProduct_body_sold{
        width: 100px;
    }
    .topProduct_body_sold span{
        font-size: 0.9rem;
        
    }
}

@media screen and  (max-width:650px){
.analysis_container{
    width: 100vw;
    padding: 12px;
}
.reports{
    flex-direction: column;
    padding: 10px;
    height: 580px;
}
.reports_users{
width: 100%;
}
.reports_topProducts {
width: 100%;  

}
.earning_chart{
    padding: 20px;
    height: 250px;
}

.top_Products > div{
    font-size: 10px;
}


.topProduct_body_name span{
    font-size:0.7rem;
    font-weight: 100;
   
}
.topProduct_body_sold{
    width: 100px;
}
.topProduct_body_sold span{
    font-size: 0.7rem;
    
}


}