.login_container{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      url("https://images.pexels.com/photos/6984650/pexels-photo-6984650.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")
        center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login_wrapper{
  width: 25%;
  padding: 20px;
  background-color: white;
  }
  .login_wrapper h1{
    font-size: 24px;
    font-weight: 300;
  }
  .login_wrapper form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .login_wrapper input{
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  }
  .login_btn{
    width: 40%;
    border: none;
    padding: 15px 20px;
    background-color: teal;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .link{
    margin: 5px 0px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    color: black;
  }
  
  /* Mobile */
  @media screen and (max-width:650px){
    .login_wrapper{
      width: 70%;
    }
    .login_btn{
      width: 50%;
    }
  }

  /* Ipads or tablets */

  @media screen and (max-width:950px){
    .login_wrapper{
      width: 60%;
    }
  }

  /* Small devices */

  @media screen and (max-width:350px){
    .login_wrapper{
      width: 80%;
    }
    .login_btn{
      width:100%;
    }
  }