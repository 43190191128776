.sideBar{
    width: 250px;
    height: 100%;
    background-color:white;
    transition: all 8ms ease;
    z-index: 10;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: black;
   display: block;
   
}

.top_section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: black;
}
.menuItem_lists{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    height: 100%;
    gap: 15px;
    margin-top: 20px;
   
}
.menuItem_lists a ,.menuItem_lists a li{
display: flex;
flex-direction: row;
align-items: center;
justify-content: left;
gap: 10px;
width: 100%;
height: 50px;
padding: 0px 10px;
cursor: pointer;
color: black;
}
.menuItem_lists a:hover{
    background-color: aliceblue;
}
#active{
    background-color: aquamarine;;
}
.top_section :nth-child(2) {
    display: none;
}

@media screen and (min-width:650px) and (max-width:950px){
    .sideBar{
        position: absolute;
        
    }   
    .top_section :nth-child(2) {
        display: block;
    }
}

@media screen and  (max-width:650px){
.sideBar{
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius:100%;
    position: absolute;
    top: 0;
    
    
}
.top_section :nth-child(2) {
    display: block;
}

}