#main{
    width: 100vw;
    background-color: #F9FAFB;
   
}
.main_body{
    min-height: 100vh; 
    position: relative;
    display: flex;
    padding-right: 40px;
    
}
.main_left{
flex:0.5;
}

.main_right{

flex: 9;
padding: 16px;
padding-right: 0px;

}
.main_footer{
    width: 100%;
    background: #FFFFFF;
    height: 120px;
    border-radius:16px ;
}
.main_footer_container{
    width: 100%;
    height: 120px;
    display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
padding: 48px 32px;

}
.copyright{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
/* line-height: 150%; */
color: #6B7280;
}


@media screen and  (max-width:650px){
    .copyright{
        font-size: 10px;
    }
    .main_footer_container{
        align-items: center;
    }
    .main_right{
        padding: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      
      
    }
    .main_left{
        flex: 0;
    }
    .main_body{
        width: 100vw;
     padding: 0;
    }
}
@media screen and (min-width:650px) and  (max-width:950px){
   
    .main_right{
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: top;
        justify-content: center;
      
      
    }
    .main_left{
        flex: 0;
       
    }
    .main_body{
    width: 100vw;
     padding: 0;
    }
}