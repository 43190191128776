.allProducts{
    height: 100vh;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 83vw;
    padding: 40px;
    
}
.products_table{
    padding-top: 30px;
    height: 100%;
    overflow-y: auto;
}
.topTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width:950px){
    .allProducts{
        padding: 20px;
      }
}