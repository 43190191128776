.profile_container {
    width: 450px;
    height: 680px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    border-radius: 8px;
    box-shadow: 0px 8px 12px rgba(0,0,0,0.12);
}

.profile_img_container {
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.profile_img_container img {

    width: 250px;
    height: 250px;
    border-radius: 100%;
    object-fit: cover;
    
}
.personal_details_container{
   
    width: 100%;
    margin-top: 12px;
    position: relative;
}


.personal_details {
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 30px;


}
.second_details{
   position: absolute;
   top: 0;
   bottom: 0;
   margin: auto;
   width: 100%;
   z-index: 10;
   margin-top: 0;
}
.personal_details span {
    width: 100%;
    padding: 10px;
    font-size: large;
    padding: 10px;
    display: flex;
   justify-content: space-between;
    align-items:center;
    

}

.personal_details span strong {
display: inline-block;    
    
}
.update_profile{
    display: flex;
    align-items: center;
    justify-content: center;
   
   
   
}
.update_profile button{
  
    width: 140px;
    height: 40px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    outline: none;
    box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.12);
    background-color: #fff;
    font-weight: 500;
    cursor: pointer;
}

.field_profile_update{
height: 100%;
outline: none;

}
#update_btn_container{
    width: 100%;
    padding: 5px ;
    height:40px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.update_profile_btn{
    width:150px ;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #fff;
    border-radius: 5px;
    
}

@media screen and (max-width:650px){
    .profile_container{
        width: 320px;
    }
  
    
    .profile_img_container img {
    
        width: 180px;
        height: 180px;
       
        
    }
    .personal_details {
        padding: 15px;
    }
    .personal_details span {
        font-size: 12px;
    }
    .personal_details span input[type=file] {
        width: 200px;
    }
    .update_profile button {
        width: 100px;
        font-size: 12px;
    }
    .update_profile_btn{
        width: 100px;
        height: 30px;
        font-size: 12px;
    }
}