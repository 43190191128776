.singlePage{
    /* height: 100vh;    */
    width: 100%;
    padding: 20px;  
    display: flex;
    justify-content: center;
    align-items: top;                                                              
}
.singlePage_container{
    width: 820px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.image_box{
    flex: 1;
    display: flex;
    align-items: top;
    justify-content: center;
}
.image_box img{
    width:350px;
    height: 350px;
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
}
.info_box{
    flex: 1;
    display: flex;
    

}

.side1,.side2{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}
.side1 div,.side2 div{
    padding: 10px;
}
.side2_sizes,.side2_storage{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
}
.side2_sizes span,.side2_storage span{
padding: 4px;
}
.desc{
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 200px;
}

.desc span{
    height: 100%;
    text-align: left;
    /* overflow: auto; */
}

.back{
    color: gray;
    display: inline;
}


@media screen and (max-width:650px){
    .singlePage_container{
        width: 100%;
    }
   
    .info_box{
        flex-direction: column;
    }
}