.register_container{
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),url("https://images.pexels.com/photos/6984661/pexels-photo-6984661.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .register_wrapper{
      width: 40%;
      padding: 20px;
      background-color: white;
    }
    .register_wrapper h1{
      font-size: 24px;
      font-weight: 300;
    }
    .register_wrapper form{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .register_wrapper form input{
      flex: 1;
      min-width: 40%;
      margin: 20px 10px 0px 0px;
      padding: 10px;
    }
    .agreement{
      font-size: 12px;
      margin: 20px 0px;
    }
    .register_btn{
      width: 40%;
      border: none;
      padding: 15px 20px;
      background-color: teal;
      color: white;
      cursor: pointer;
    }


    @media screen and (max-width:650px){
      .register_wrapper{
        width: 70%;
      }
   
    }
  
    /* Ipads or tablets */
  
    @media screen and (max-width:950px){
      .register_wrapper{
        width: 80%;
      }
    }
  
    /* Small devices */
  
    @media screen and (max-width:350px){
      .register_wrapper{
        width: 100%;
      }
     
    }