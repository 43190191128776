#allproduct{
    width: 100vw;
    min-height: 100vh;
    background-color: #f1f3f6;
}
.allProduct-wrapper{
    width: 100%;
    padding: 2% 12%;
    border-radius: 5px;
    display: flex;
    
   
}
.filter-sidebar{
    flex: 2;
    padding: 0 10px;
   
}
.product-container{
    flex: 8;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.product{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 25px;
  
}
.pagination{
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
}

@media screen and ( max-width:650px ){
.allProduct-wrapper{
    
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.filter-sidebar{

    flex: 2;
    padding: 0 10px;
    margin-top: 20px;
    
}

.product{
   justify-content: center;
}
}

@media screen and (min-width:650px) and (max-width:950px ){
.allProduct-wrapper{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    
   
}

.filter-sidebar{
    padding: 0 120px;

}
}