
.order_container{
   height: 80vh;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 83vw;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    z-index: 1;

}
.order_table{
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
  
}

@media screen and  (max-width:650px){
    .order_container{
        padding: 30px;
       
    }
}
@media screen and (min-width:650px) and  (max-width:950px){
    .order_container{
        padding: 30px;
       
    }
}